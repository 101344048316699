import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { Spinner } from '@amzn/awsui-components-react';

import { AnswerChallengeForm } from '../components/AnswerChallengeForm';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import HelpLink from '../components/HelpLink';
import Main from '../components/Main';
import TopHeader from '../components/TopHeader';
import { VERIFY_EMAIL_COOKIE_NAME } from '../components/postAuth/VerifyEmail';
import emailVerificationMessages from '../i18n/emailVerification.messages';
import * as AuthService from '../services/emailOTPAuth';
import { getInitialLocaleSelection } from '../services/locale';
import { AppConfig } from '../types/app';
import usePageTitle from '../utils/usePageTitle';
import { useHistory } from 'react-router-dom';
import { AppURL } from '../constants/urls';

function SendVerificationEmail({ config }: { config: AppConfig }) {
  if (
    config.enableEmailVerification === 'false' ||
    !config.enableEmailVerification
  ) {
    throw new Error('Invalid path');
  }
  usePageTitle('Verify your email');
  AuthService.init(config);
  const [cognitoUserName, setCognitoUserName] = useState('');
  const [cognitoSession, setCognitoSession] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  useEffect(() => {
    if (!email) {
      const userEmail = Cookies.get(VERIFY_EMAIL_COOKIE_NAME);
      if (!userEmail) {
        // If the cookie isn't present, give the user the option to enter their own email
        history.push({
          pathname: AppURL.OTPInput,
          search: history.location.search,
        });
        return;
      }
      setEmail(userEmail);
    }
    // eslint-disable-next-line
  }, []);

  // get email from cookie, throw error if it doesn't exist

  async function sendVerificationEmail(userEmail: string) {
    const isNewUser = (await AuthService.newUser(userEmail)) as boolean;
    if (isNewUser) {
      await AuthService.signUp(userEmail);
    }
    const cognitoUser = await AuthService.startSession(userEmail);
    await AuthService.answerLocaleChallenge(
      getInitialLocaleSelection(),
      cognitoUser
    );
    setCognitoUserName(cognitoUser.getUsername());
    setCognitoSession((cognitoUser as any).Session);
  }

  useEffect(() => {
    if (email) {
      sendVerificationEmail(email as string);
    }
  }, [email]);

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          {cognitoUserName && cognitoSession ? (
            <>
              <AnswerChallengeForm
                config={config}
                headerMsg={emailVerificationMessages.header}
                descriptionMsg={emailVerificationMessages.description}
                showBackButton={false}
                useUser={{
                  userName: cognitoUserName,
                  session: cognitoSession,
                  email: email as string,
                }}
              />
              <HelpLink />
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="large" />
            </div>
          )}
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default SendVerificationEmail;
