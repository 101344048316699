import { useEffect, useRef, useState } from 'react';

import metricsService from '../services/metrics';
import providersService from '../services/providers';
import useGandalfPostAuth from '../hooks/useGandalfPostAuth';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import { AppURL } from '../constants/urls';
import { IMPRESSIONS } from '../constants/metrics';
import { redirectToLXPAuth } from '../services/auth';
import EulaCheck from '../components/postAuth/EulaCheck';
import AccountLinkConsent from '../components/postAuth/AccountLinkConsent';
import VerifyEmail from '../components/postAuth/VerifyEmail';
import LearnerConsentOnMerge3p from '../components/postAuth/LearnerConsentOnMerge3p';
import { IdProvider, PostAuthComponentParams } from '../types/auth';
import { AppConfig } from '../types/app';
import SignInUnauthorized from '../components/SignInUnauthorized';
import SignInFailed from '../components/SignInFailed';
import UnauthorizedError from '../errors/UnauthorizedError';
import { IDP_URL_PARAM } from '../constants/auth';
import MissingEmailError from '../errors/MissingEmailError';
import MissingEmail from '../components/MissingEmail';
import { useExplicitAmplifyConfig } from '../hooks/useAmplifyConfig';
import useIdpFromState from '../hooks/useIdpFromState';
import { rumService } from '../services/rum';
import { getInitialLocaleSelection } from '../contexts/LocaleContextProvider';
import { DEFAULT_LOCALE } from '../constants/locales';
import { AccountLinkWarning } from '../components/postAuth/AccountLinkWarning';
import { GandalfSession } from '../components/postAuth/GandalfSession';
import LwaNewUserPrevention from '../components/LwaNewUserPrevention';
import { IdPs, isStandardProvider } from '../constants/providers';

enum PostAuthFlows {
  LwaNewUserPrevention = 'LwaNewUserPrevention',
  EULA = 'EULA',
  AccountLinkConsent = 'Account Link Consent',
  AccountLinkWarning = 'AccountLinkWarning',
  EmailVerification = 'Email Verification',
  GandalfSession = 'Gandalf Session',
  LearnerConsentOnMerge3p = 'Learner Consent On Merge 3P',
}

type PostAuthComponent = {
  type: PostAuthFlows;
  component: React.FC<PostAuthComponentParams>;
};

/**
 * List all post auth flow components in the order they should be displayed.
 */
const postAuthFlows: PostAuthComponent[] = [
  { type: PostAuthFlows.GandalfSession, component: GandalfSession },
  { type: PostAuthFlows.LwaNewUserPrevention, component: LwaNewUserPrevention },
  { type: PostAuthFlows.AccountLinkConsent, component: AccountLinkConsent },
  { type: PostAuthFlows.EmailVerification, component: VerifyEmail },
  { type: PostAuthFlows.EULA, component: EulaCheck },
  { type: PostAuthFlows.AccountLinkWarning, component: AccountLinkWarning },
  {
    type: PostAuthFlows.LearnerConsentOnMerge3p,
    component: LearnerConsentOnMerge3p,
  },
];

/**
 * Replaces current URL state to enable "back" and reload in the browser.
 */
function replaceUrlWithReloadSafeParams(searchParams: string) {
  const refreshParams = new URLSearchParams(searchParams);
  // Remove the identity_provider to not trigger preferred IDP flow.
  refreshParams.delete(IDP_URL_PARAM);
  window.history.replaceState(
    {},
    document.title,
    `${AppURL.Login}?${refreshParams.toString()}`
  );
}

function AuthResponse({ config }: { config: AppConfig }) {
  const [flowIndex, setFlowIndex] = useState(0);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [provider, setProvider] = useState<IdProvider | undefined>(undefined);
  const idpFromState = useIdpFromState();
  // Note: The first call to `Auth.configure` from this page will trigger a token exchange.  So, we have to ensure the
  // correct IDP configuration is provided.
  useExplicitAmplifyConfig(config, provider || idpFromState);
  const {
    user,
    error: postAuthError,
    customState,
  } = useGandalfPostAuth(false, config);
  const metricsPublisher = useRef(metricsService.getPublisher('AuthResponse'));
  const [authComponentError, setError] = useState(undefined);

  const error = postAuthError || authComponentError;

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
  }, []);

  // Await auth flow and custom OIDC state, the state contains the persistes search params.
  // When this is received we can start post auth flows and then continue redirecting to
  // the LXP specific auth.
  useEffect(() => {
    if (!customState) return;
    const idpConfig: IdProvider = JSON.parse(customState);
    const searchParams = new URL(idpConfig.url).search;

    const urlSearchParams = new URLSearchParams(searchParams);
    const idp = urlSearchParams.get(IDP_URL_PARAM);
    // Update the URL
    replaceUrlWithReloadSafeParams(searchParams);
    // Get the provider. Note, it will use values from the URL.
    const provider = providersService.transformNameToIdProvider(
      idp!,
      config.gandalfDomain
    );
    setProvider(provider);
  }, [config.gandalfDomain, customState]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (user?.new_user === true || user?.new_user === 'true') {
      rumService.recordEvent('vibe_created', {
        idp: user.providerName,
        clientId: urlSearchParams.get('client_id'),
        linkingCandidate: Boolean(user.associate_to),
        languageSelected: getInitialLocaleSelection(DEFAULT_LOCALE),
      });
    }
    if (user?.new_user === false || user?.new_user === 'false') {
      rumService.recordEvent('existing_user', {
        idp: user?.providerName,
        clientId: urlSearchParams.get('client_id'),
        linkingCandidate: Boolean(user?.associate_to),
        languageSelected: getInitialLocaleSelection(DEFAULT_LOCALE),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.new_user]);

  const handleContinue = () => {
    const nextFlowIndex = flowIndex + 1;
    metricsPublisher.current.publishCounterMonitor('PostAuthContinue', 1);
    // Are we ready to redirect to LXP auth?
    if (nextFlowIndex === postAuthFlows.length) {
      redirectToLXPAuth(
        config.enableGandalfSession === 'true' &&
          config.gandalfSessionEndpoint &&
          !isStandardProvider(provider!.idp)
          ? providersService.transformNameToIdProvider(
              IdPs.GandalfSession,
              config.gandalfDomain
            )
          : provider!
      );
      setIsRedirecting(true);
    } else {
      setFlowIndex(nextFlowIndex);
    }
  };

  const handleCancel = () => {
    metricsPublisher.current.publishCounterMonitor('PostAuthCancel', 1);
    window.location.reload();
  };

  const isAuthenticatedWithProvider = Boolean(user && provider);

  if (error) {
    if (error instanceof UnauthorizedError) {
      return (
        <SignInUnauthorized
          config={config}
          error={error}
          provider={provider!}
        />
      );
    } else if (error instanceof MissingEmailError) {
      return (
        <MissingEmail config={config} error={error} provider={provider!} />
      );
    }
    return <SignInFailed config={config} error={error} provider={provider!} />;
  }

  if (isRedirecting) return null;
  if (!isAuthenticatedWithProvider) return null;

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  const AuthFlowComponent = postAuthFlows[flowIndex].component;
  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          <AuthFlowComponent
            user={user!}
            onContinue={handleContinue}
            onCancel={handleCancel}
            config={config}
            error={error ?? undefined}
            setError={setError}
          />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default AuthResponse;
