import { useIntl } from 'react-intl';
import { TextContent } from '@amzn/awsui-components-react';

import Footer from '../components/Footer';
import { AppConfig } from '../types/app';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import ContainerLayout from '../components/ContainerLayout';
import CardContainer from '../components/CardContainer';
import styles from '../index.module.css';
import errorMessages from '../i18n/error.messages';

const AppInitFail = ({ config }: { config?: AppConfig }) => {
  const { formatMessage } = useIntl();
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  return (
    <div className={styles.wrapper}>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          <CardContainer
            header={formatMessage(errorMessages.errorHeader)}
            description={formatMessage(errorMessages.errorSubHeaderDescription)}
          >
            <TextContent>
              <p>
                {formatMessage(errorMessages.initFailedInfoP1, {
                  a: (chunks) => (
                    <a href={`${window.location.href}`}>{chunks}</a>
                  ),
                })}
              </p>
              <p>{formatMessage(errorMessages.initFailedInfoP2)}</p>
            </TextContent>
          </CardContainer>
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </div>
  );
};

export default AppInitFail;
