import { defineMessages } from 'react-intl';

const learnerConsentOnMerge3pMessages = defineMessages({
  header: {
    id: 'learnerConsentOnMerge3p.header',
    defaultMessage: 'Organization Access Data notice',
    description: 'Header of the Learner Consent On Merge 3P page.',
  },
  acceptButton: {
    id: 'learnerConsentOnMerge3p.consentButton',
    defaultMessage: 'Accept and join Organization',
    description:
      'Button to accept joining organization for Learner Consent On Merge 3P.',
  },
  declineButton: {
    id: 'learnerConsentOnMerge3p.declineButton',
    defaultMessage: 'Do not join organization',
    description:
      'Button to decline joining organization for Learner Consent On Merge 3P.',
  },
  cancelButton: {
    id: 'learnerConsentOnMerge3p.cancelButton',
    defaultMessage: 'Cancel',
    description: 'Button to cancel.',
  },
  confirmButton: {
    id: 'learnerConsentOnMerge3p.confirmButton',
    defaultMessage: 'Confirm',
    description: 'Button to confirm.',
  },
  okButton: {
    id: 'learnerConsentOnMerge3p.okButton',
    defaultMessage: 'Ok',
    description: 'Button to acknowledge.',
  },
  learnerConsentOnMerge3pDescription: {
    id: 'learnerConsentOnMerge3p.description',
    defaultMessage:
      'You are invited to join the Organization <b>"{organization}"</b>. Once you accept, your organization will have access to your profile information, preferences and training data.',
    description: 'Learner Consent On Merge 3P description.',
  },
  verificationError: {
    id: 'learnerConsentOnMerge3p.verificationError',
    defaultMessage:
      'An error occurred while completing Learner Consent On Merge. Please try again.',
    description: 'Error when Learner Consent On Merge 3P completion fails.',
  },
  confirmationModalHeader: {
    id: 'learnerConsentOnMerge3p.confirmationModalHeader',
    defaultMessage: 'Are you sure?',
    description: 'Header for confirmation modal.',
  },
  confirmationModalDescription: {
    id: 'learnerConsentOnMerge3p.confirmationModalDescription',
    defaultMessage:
      "You chose not to join the organization. If you want to join your Organization's AWS training in the future, contact your learning admin to get a new invitation.",
    description: 'Description for confirmation modal.',
  },
  confirmationModalDescriptionESSO: {
    id: 'learnerConsentOnMerge3p.confirmationModalDescriptionESSO',
    defaultMessage:
      "You chose not to join the organization. If you want to join your Organization's AWS training in the future, sign-in again with your single sign-on credentials.",
    description: 'Description for confirmation modal.',
  },
});

export default learnerConsentOnMerge3pMessages;
