export const IMPRESSIONS = 'Impressions';

export const UPDATE_EULA = 'UpdateEula';

export const CHECK_NEEDS_VERIFICATION = 'CheckNeedsVerification';

export const GANDALF_SESSION = 'GandalfSession';

export const UPDATE_USER_MERGE_CONSENTED = 'UpdateUserMergeConsented';

export const CHECK_USER_MERGE_CONSENT_REQUIRED =
  'CheckUserMergeConsentRequired';
