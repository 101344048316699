import { useIntl } from 'react-intl';
import { Badge, Header, Icon } from '@amzn/awsui-components-react';

import { IdPs } from '../constants/providers';
import { providerMessages } from '../i18n/provider.messages';
import { IdProvider } from '../types/auth';
import bidIcon from '../assets/AWS_logo_RGB_light_mode.svg';
import lwaIcon from '../assets/lwa.svg';
import styles from './ProviderButton.module.css';
import { Button } from '@amzn/awsui-components-react/polaris';
import signinMessages from '../i18n/signin.messages';
import React from 'react';
import IdpDescription from './IdpDescription';

const ProviderButton = ({
  provider,
  onClick,
  lastUsedIdp,
  ...props
}: {
  provider: IdProvider;
  onClick: Function;
  lastUsedIdp?: boolean;
}) => {
  const { formatMessage } = useIntl();

  const providerButtonLabel =
    provider.idp === IdPs.AWSBuilderID
      ? formatMessage(signinMessages.createOrSignInButton)
      : formatMessage(signinMessages.signinButton);

  const providerHeaderLabel = IdPs[provider.idp as IdPs]
    ? formatMessage(providerMessages[provider.idp as IdPs], {
        i: (str) => <i>{str}</i>,
      })
    : provider.idp;

  // apply styling depending on the provider
  let buttonStyle = undefined;
  let iconUrl = '';
  switch (provider.idp) {
    case IdPs.AWSBuilderID:
      iconUrl = bidIcon;
      buttonStyle = styles.siginInButtonWithDescription;
      break;
    case IdPs.LoginWithAmazon:
      iconUrl = lwaIcon;
      buttonStyle = styles.siginInButtonWithDescription;
      break;
  }
  if (provider.idp === IdPs.LoginWithAmazon) {
    return <></>;
  }
  return (
    <div
      className={
        IdPs.AmazonFederate === provider.idp && lastUsedIdp === false
          ? styles.idpBox_last
          : styles.idpBox
      }
    >
      <div className={[styles.rowElements].join(' ')}>
        {iconUrl && (
          <div className={[styles.bid].join('')}>
            <Icon
              size="large"
              url={iconUrl}
              alt={formatMessage(providerMessages[IdPs.AWSBuilderID])}
            />
          </div>
        )}
        <div className={styles.idpHeader}>
          <Header
            id={`${provider.idp}-providerLabel`}
            data-testid="signin-providerLabel"
            variant="h2"
            description={IdpDescription({ provider })}
          >
            {provider.lastUsedIdpDisplayName
              ? provider.lastUsedIdpDisplayName
              : IdPs[provider.idp as IdPs]
              ? formatMessage(providerMessages[provider.idp as IdPs], {
                  i: (str) => <i>{str}</i>,
                })
              : provider.idp}{' '}
            {provider.idp === IdPs.AWSBuilderID ? (
              <Badge className={styles.customBadge} color="blue">
                {formatMessage(signinMessages.builderIdRecommendation)}
              </Badge>
            ) : (
              <></>
            )}
            {provider.idp === IdPs.AWSPartnerNetwork ? (
              <Badge className={styles.customBadge} color="blue">
                {formatMessage(signinMessages.apnBadgeRecommendation)}
              </Badge>
            ) : (
              <></>
            )}
          </Header>
        </div>
        <div
          className={
            buttonStyle === undefined
              ? styles.siginIn
              : styles.siginInButtonWithDescription
          }
        >
          <Button
            wrapText={false}
            onClick={() => onClick(provider)}
            data-testid={`${provider.idp}-provider-button`}
            id={`${provider.idp}-provider-button`}
            ariaLabel={`${providerButtonLabel} ${providerHeaderLabel}`}
          >
            {providerButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProviderButton;
