import { useState } from 'react';
import { GET_PUBLIC_IDP_DETAILS_PATH } from '../constants/api';
import providersService from '../services/providers';

import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';
import { request } from '../utils/apiHelper';
import logger from '../utils/logger';
import useProviders from './useProviders';
import { GetEmailDomainIdpOutput } from '../constants/providers';

type QueryResult = {
  isFound: boolean;
  provider?: IdProvider;
};

export type GetPublicIdpDetailsOutput = {
  authEndpoint?: string;
  userPoolId?: string;
  clientId?: string;
  displayName?: string;
};

/**
 * Lookup the public IDP details using the Gandalf IDP API.
 */
async function queryApi(
  config: AppConfig,
  idp: string
): Promise<GetPublicIdpDetailsOutput | undefined> {
  const response = await request(
    `${config.gandalfIDPEndpoint}${GET_PUBLIC_IDP_DETAILS_PATH}/${idp}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { metricNs: 'GetPublicIdpDetails', treat404AsOk: true }
  );
  if (response.status === 404) return;
  const result: GetPublicIdpDetailsOutput = await response.json();
  return result;
}

function useGetPublicIdpDetails(config: AppConfig, idpProviders: IdProvider[]) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [result, setResult] = useState<QueryResult | undefined>();
  const { providers } = useProviders(config);
  let emailDomainIdpOutput: GetEmailDomainIdpOutput = { idp: '' };

  function getPublicIdpDetails(idp: string) {
    setIsLoading(true);
    setError(undefined);
    setResult(undefined);
    queryApi(config, idp)
      .then((output) => {
        emailDomainIdpOutput.idp = idp;
        const provider = output
          ? providersService.getProvider(
              emailDomainIdpOutput,
              providers ?? idpProviders
            )
          : undefined;
        if (output?.authEndpoint) {
          setResult({
            isFound: true,
            provider: {
              idp: idp,
              url: providersService.getIdpUrl(idp, output.authEndpoint),
              userPoolId: output.userPoolId,
              clientId: output.clientId,
              lastUsedIdpDisplayName: output.displayName,
            },
          });
        } else {
          setResult({
            isFound: Boolean(provider),
            provider,
          });
        }
      })
      .catch((error) => {
        logger.debug(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  }

  function resetResult() {
    setResult(undefined);
  }

  return {
    isLoading,
    error,
    result,
    getPublicIdpDetails,
    resetResult,
  };
}

export default useGetPublicIdpDetails;
