import * as awsui from '@amzn/awsui-design-tokens/polaris';
import { AppConfig } from '../types/app';

const Main = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config?: AppConfig;
}) => {
  const mainStyle: any = {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor:
      config?.enableCloudScapeVisualRefresh !== 'true'
        ? awsui.colorBackgroundLayoutMain
        : 'initial',
  };

  return <main style={mainStyle}>{children}</main>;
};

export default Main;
