import React, { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import jwtDecode from 'jwt-decode';
import { getAmplifyConfig } from '../components/postAuth/ValidateAccountLink';
import { AppConfig } from '../types/app';
import Error from '../pages/Error';
import {
  TextContent,
  Container,
  Header,
  Form,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { AppURL } from '../constants/urls';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { useIntl } from 'react-intl';
import styles from '../components/CardContainer.module.css';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import { IdPs } from '../constants/providers';
import { AccountLinkContainer } from '../components/AccountLinkContainer';
import { AccountLinkICantButton } from '../components/AccountLinkICantButton';
import { AccountLinkSubmitForm } from '../components/AccountLinkSubmitForm';
import { LAST_USED_GANDALF_TOKEN, LAST_USED_STATE } from '../constants/auth';
import { AuthToken, unmarshalAuthToken } from '../utils/authToken';
import CantValidateAccount from '../components/CantValidateAccount';

function replaceUrlWithReloadSafeParams(searchParams: string) {
  const refreshParams = new URLSearchParams(searchParams);
  window.history.replaceState(
    {},
    document.title,
    `${AppURL.SignOutToAllowSignInFlowRedirect}?${refreshParams.toString()}`
  );
}

const SignOutToAllowSignInFlowRedirect = ({
  config,
}: {
  config: AppConfig;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const [showCant, setShowCant] = useState(false);

  const amplifyConfig = getAmplifyConfig(config);
  Auth.configure(amplifyConfig.Auth);

  let authToken: AuthToken | null;

  let marshalledGandalfAuthToken = localStorage.getItem(
    LAST_USED_GANDALF_TOKEN
  );

  const lastUsedStateValue = localStorage.getItem(LAST_USED_STATE);

  try {
    authToken = unmarshalAuthToken(
      marshalledGandalfAuthToken!,
      lastUsedStateValue!
    );
  } catch (e) {
    <Error config={config} details="Auth Token Not Found" />;
  }

  useEffect(() => {
    if (authToken) {
      replaceUrlWithReloadSafeParams(authToken.originalRequestURL);
    }
    // eslint-disable-next-line
  }, []);

  if (authToken!) {
    //decode the authToken to get the jwt token
    const authTokenDecoded: any = jwtDecode(authToken?.jwtToken ?? '');
    const provider = authTokenDecoded['identities'];
    const userEmail = authTokenDecoded['email'];
    const searchParams = authToken.originalRequestURL;
    replaceUrlWithReloadSafeParams(searchParams);

    return (
      <>
        <AccountLinkContainer config={config}>
          {showCant ? (
            <CantValidateAccount
              authToken={authToken}
              config={config}
              onDismiss={() => setShowCant(false)}
            />
          ) : (
            <Container
              header={
                <>
                  <Header
                    className={styles.header}
                    variant="h2"
                    headingTagOverride="h1"
                  >
                    {formatMessage(
                      accountlinkConsent.signOutToAllowSignInHeaderStepTwoHeader
                    )}
                  </Header>
                </>
              }
            >
              <Form
                actions={
                  <>
                    <SpaceBetween direction="horizontal" size="xs">
                      <AccountLinkICantButton
                        authToken={authToken}
                        onCantClicked={() => setShowCant(true)}
                      />
                      <AccountLinkSubmitForm
                        buttonLabel={formatMessage(
                          accountlinkConsent.validateWithIdpButton
                        )}
                        authToken={authToken}
                        nonce={authToken.nonce}
                        provider={provider}
                        associateIdpTo={IdPs.LoginWithAmazon}
                        config={config}
                        lwaCheckRequired={false}
                        setRenderAlert={() => {}}
                      />
                    </SpaceBetween>
                  </>
                }
              >
                <SpaceBetween direction="vertical" size="s">
                  <TextContent>
                    {formatMessage(
                      accountlinkConsent.signOutToAllowSignInStepTwoContent,
                      {
                        originalUserEmail: userEmail,
                        b: (str) => <b>{str}</b>,
                      }
                    )}{' '}
                  </TextContent>
                  <TextContent>
                    <a
                      href={`${AppURL.AccountLinkFAQ}`}
                      target="_blank"
                      data-testid="account-link-help-link"
                      rel="noopener noreferrer"
                      onClick={() => {
                        emitAccountLinkingRUMEvents(
                          authToken?.jwtToken!,
                          'confirm_exisiting_profile_learn_more_button'
                        );
                      }}
                    >
                      {formatMessage(
                        accountlinkfaqPageDetails.learnMoreAboutProfileLinking
                      )}
                    </a>
                  </TextContent>
                </SpaceBetween>
              </Form>
            </Container>
          )}
        </AccountLinkContainer>
      </>
    );
  } else {
    return <Error config={config} details="Auth Token Not Found" />;
  }
};

export default SignOutToAllowSignInFlowRedirect;
