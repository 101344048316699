import { IdPs } from '../../constants/providers';
import { useNonceGenerator } from '../../contexts/NonceGeneratorContextProvider';
import { buildNonce } from '../../services/auth';
import {
  AuthToken,
  GandalfUser,
  PostAuthComponentParams,
} from '../../types/auth';
import SignInFailed from '../SignInFailed';
import Main from '../Main';
import ContainerLayout from '../ContainerLayout';
import usePageTitle from '../../hooks/usePageTitle';
import accountlinkConsent from '../../i18n/accountlinkConsent.messages';
import { useIntl } from 'react-intl';
import {
  Container,
  Form,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import AccountLinkFailed from '../../pages/AccountLinkFailed';
import { AppURL } from '../../constants/urls';
import { AppConfig } from '../../types/app';
import { MemoryAuthStorage } from '../../authConfig/amplify';
import React from 'react';
import { useHistory } from 'react-router-dom';
import providersService from '../../services/providers';
import { emitAccountLinkingRUMEvents } from '../../services/emitAccountLinkingRUMEvent';
import styles from '../CardContainer.module.css';

import SingleProfileAccountLink from '../SingleProfileAccountLink';
import SingleProfileLinkHeader from '../SingleProfileLinkHeader';
import MultipleProfileLinkHeader from '../MultipleProfileLinkHeader';
import MultipleProfileLinkContent from '../MultipleProfileLinkContent';
import { AccountLinkICantButton } from '../AccountLinkICantButton';
import { AccountLinkSubmitForm } from '../AccountLinkSubmitForm';
import { renderProviderName } from '../../utils/renderProviderName';
import CantValidateAccount from '../CantValidateAccount';

export function replaceUrlWithReloadSafeParams(searchParams: string) {
  const refreshParams = new URLSearchParams(searchParams);
  return `${AppURL.Login}?${refreshParams.toString()}`;
}

//Configure Amplify for Federated Sign IN
export function getAmplifyConfig(config: AppConfig) {
  const redirectUrl = `${window.location.origin}/accountlinkvalidate`;

  return {
    Auth: {
      storage: MemoryAuthStorage,
      region: config.accountLinkValidateRegion,
      userPoolId: config.accountLinkAuthUserPoolID,
      userPoolWebClientId: config.accountLinkAuthClientID,
      oauth: {
        domain: config.accountLinkAuthGandalfDomain,
        scope: ['openid'],
        responseType: 'code',
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
      },
    },
  };
}

const ValidateAccountLink = ({
  user,
  onContinue,
  onCancel,
  config,
  error,
}: PostAuthComponentParams) => {
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(accountlinkConsent.pageTitle));
  const history = useHistory();
  const nonceGenerator = useNonceGenerator();

  const [singleProfileLink, setSingleProfileLink] = useState<boolean>(true);
  const [associateIdpTo, setAssociateIdpTo] = useState('');
  const [renderAlert, setRenderAlert] = useState<boolean>(false);
  const [idpSelectError, setIdpSelectError] = useState('');
  const [showCant, setShowCant] = useState(false);

  const provider = providersService.transformNameToIdProvider(
    user.providerName,
    config.gandalfDomain
  );

  const checkMultipleLwaScenarios = (user: GandalfUser): boolean => {
    const existingLwa = (): boolean => {
      const idpSet = new Set(user.associate_to?.LinkedToIDP);
      return (
        idpSet.has(IdPs.LoginWithAmazon.toString()) &&
        new Set(user.associate_to?.LinkedToIDP).size === 1
      );
    };

    let multipleLwaScenarios = false;

    if (
      user.providerName === IdPs.LoginWithAmazon.toString() &&
      existingLwa()
    ) {
      multipleLwaScenarios = true;
    }

    return multipleLwaScenarios;
  };

  const initiateMultipleLwaFlow = (authToken: AuthToken): void => {
    const urlParams = new URLSearchParams(window.location.search);
    history.push({
      pathname: AppURL.MultipleLwaAccountLinkSupport,
      search: urlParams.toString(),
      state: {
        authToken: authToken,
      },
    });
  };

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        history.push(replaceUrlWithReloadSafeParams(window.location.search));
      }
    };
    if (authToken.jwtToken) {
      emitAccountLinkingRUMEvents(
        authToken.jwtToken,
        'confirm_exisiting_profile_screen_shown'
      );
    }
    if (user.associate_to) {
      if (
        Array.isArray(user.associate_to.LinkedToIDP) &&
        user.associate_to.LinkedToIDP.length > 1
      ) {
        setSingleProfileLink(false);
      } else {
        setAssociateIdpTo(user.associate_to.LinkedToIDP.toString());
      }
    }
    if (checkMultipleLwaScenarios(user)) {
      initiateMultipleLwaFlow(authToken);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (renderAlert === true) {
      setIdpSelectError(formatMessage(accountlinkConsent.renderIdpSelectAlert));
    } else {
      setIdpSelectError('');
    }
    // eslint-disable-next-line
  }, [renderAlert]);

  useEffect(() => {
    if (associateIdpTo as IdPs) {
      setIdpSelectError('');
    }
  }, [associateIdpTo]);

  const nonce = buildNonce(nonceGenerator);
  var urlParams = new URLSearchParams(window.location.search);
  urlParams.set('identity_provider', user.providerName);
  const authToken: AuthToken = {
    originalRequestURL: urlParams.toString(),
    jwtToken: user.jwtToken ?? '',
    nonce,
  };

  if (user.jwtToken && user.associate_to) {
    try {
      return (
        <>
          <Main config={config}>
            <ContainerLayout>
              <div className={styles.heading_div}>
                <TextContent>
                  <h1>{formatMessage(accountlinkConsent.header)}</h1>
                </TextContent>
              </div>
              {showCant ? (
                <CantValidateAccount
                  authToken={authToken}
                  config={config}
                  onDismiss={() => setShowCant(false)}
                />
              ) : (
                <Container
                  header={
                    <>
                      <>
                        {singleProfileLink ? (
                          <>
                            <SingleProfileLinkHeader
                              user={user}
                              config={config}
                            />
                          </>
                        ) : (
                          <>
                            <MultipleProfileLinkHeader
                              user={user}
                              config={config}
                            />
                          </>
                        )}
                      </>
                    </>
                  }
                >
                  <Form
                    errorText={idpSelectError}
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <AccountLinkICantButton
                          authToken={authToken}
                          onCantClicked={() => setShowCant(true)}
                        />
                        {singleProfileLink ? (
                          <>
                            <AccountLinkSubmitForm
                              buttonLabel={formatMessage(
                                accountlinkConsent.confirmExistingProfileOwnershipButton,
                                {
                                  currentProviderName: renderProviderName(
                                    associateIdpTo as IdPs,
                                    formatMessage
                                  ),
                                }
                              )}
                              authToken={authToken}
                              provider={user.providerName}
                              nonce={nonce}
                              associateIdpTo={associateIdpTo}
                              config={config}
                              lwaCheckRequired={false}
                              setRenderAlert={() => {}}
                            />
                          </>
                        ) : (
                          <>
                            <AccountLinkSubmitForm
                              buttonLabel={formatMessage(
                                accountlinkConsent.continueToNextStepButton
                              )}
                              authToken={authToken}
                              provider={user.providerName}
                              nonce={nonce}
                              associateIdpTo={associateIdpTo}
                              config={config}
                              lwaCheckRequired={true}
                              setRenderAlert={setRenderAlert}
                            />
                          </>
                        )}
                      </SpaceBetween>
                    }
                  >
                    {singleProfileLink ? (
                      <>
                        {' '}
                        <SingleProfileAccountLink
                          user={user}
                          config={config}
                          authToken={authToken}
                        />
                      </>
                    ) : (
                      <>
                        <MultipleProfileLinkContent
                          user={user}
                          config={config}
                          authToken={authToken}
                          setIdpSelected={setAssociateIdpTo}
                        />
                      </>
                    )}
                  </Form>
                </Container>
              )}
            </ContainerLayout>
          </Main>
        </>
      );
    } catch (e: any) {
      // eslint-disable-next-line
      console.log(e);
      return (
        <AccountLinkFailed
          error={e}
          authToken={authToken.jwtToken}
          config={config}
        />
      );
    }
  } else {
    return (
      <SignInFailed
        error={error ?? new Error('SignIn Failed')}
        provider={provider}
      />
    );
  }
};

export default ValidateAccountLink;
