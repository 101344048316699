import React, { SyntheticEvent, useEffect, useState } from 'react';
import { AppConfig } from '../types/app';
import {
  TextContent,
  Container,
  Header,
  Form,
  SpaceBetween,
  Button,
  Box,
} from '@amzn/awsui-components-react';
import { AppURL } from '../constants/urls';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { AuthToken } from '../utils/authToken';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import styles from './CardContainer.module.css';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import Error from '../pages/Error';
import { IdPs } from '../constants/providers';
import format from 'date-fns/format';
import { AccountLinkContainer } from './AccountLinkContainer';

const MultipleExistingLwaSupport = ({
  config,
}: {
  config: AppConfig;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [singleExistingLwa, setSingleExistingLwa] = useState<boolean>(true);
  var authToken: any = '';

  const hState = history.location.state;

  if (hState && (hState as any).authToken) {
    authToken = (hState as any).authToken;
  }

  useEffect(() => {
    if (authToken) {
      const authTokenDecoded: any = jwtDecode(authToken?.jwtToken! ?? '');
      const associate_to = JSON.parse(authTokenDecoded['associate_to']);
      const vibes: string[] = associate_to?.VibeId!;
      const idPSet = new Set(vibes);
      setSingleExistingLwa(
        idPSet.size === 1 &&
          associate_to.LinkedToIDP[0] === IdPs.LoginWithAmazon.toString()
      );
    }
  }, [authToken]);

  const initiateSignOutToSignInFlow = (authToken: AuthToken): void => {
    const urlParams = new URLSearchParams(window.location.search);
    history.push({
      pathname: AppURL.SignOutToAllowSignIn,
      search: urlParams.toString(),
      state: {
        authToken: authToken,
      },
    });
  };

  if (authToken) {
    const authTokenDecoded: any = jwtDecode(authToken?.jwtToken ?? '');
    const associate_to = JSON.parse(authTokenDecoded['associate_to']);
    const userEmail = authTokenDecoded['email'];

    const handleFormSubmit = (event: SyntheticEvent) => {
      event.preventDefault();
      emitAccountLinkingRUMEvents(
        authToken?.jwtToken ?? '',
        'MultipleLwaProfileLinking'
      );
      return initiateSignOutToSignInFlow(authToken!);
    };

    return (
      <>
        <AccountLinkContainer config={config}>
          <Container
            header={
              <>
                <Header
                  className={styles.header}
                  variant="h2"
                  headingTagOverride="h1"
                >
                  {formatMessage(
                    accountlinkConsent.multipleLwaHeaderStepOneHeader
                  )}
                </Header>
              </>
            }
          >
            <form onSubmit={handleFormSubmit} noValidate>
              <Form
                actions={
                  <>
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="primary"
                        data-testid="verify-lwa"
                        formAction="submit"
                      >
                        {formatMessage(
                          accountlinkConsent.continueToNextStepButton
                        )}
                      </Button>
                    </SpaceBetween>
                  </>
                }
              >
                <SpaceBetween direction="vertical" size="s">
                  <TextContent>
                    {singleExistingLwa
                      ? formatMessage(accountlinkConsent.lwaToLwaContentText, {
                          originalUserEmail: userEmail,
                          br: <br />,
                          b: (str) => <b>{str}</b>,
                          lastUsedDate: format(
                            new Date(associate_to.LinkedToLastSignedIn[0]),
                            'MMMM d, yyyy'
                          ),
                        })
                      : formatMessage(
                          accountlinkConsent.multipleLwaHeaderStepOneContent,
                          {
                            originalUserEmail: userEmail,
                            br: <br />,
                            b: (str) => <b>{str}</b>,
                          }
                        )}
                  </TextContent>
                  <TextContent>
                    <Box margin={{ top: 's' }} float="left">
                      <a
                        href={`${AppURL.AccountLinkFAQ}`}
                        target="_blank"
                        data-testid="account-link-help-link"
                        rel="noopener noreferrer"
                        onClick={() => {
                          emitAccountLinkingRUMEvents(
                            authToken?.jwtToken!,
                            'confirm_exisiting_profile_learn_more_button'
                          );
                        }}
                      >
                        {formatMessage(
                          accountlinkfaqPageDetails.learnMoreAboutProfileLinking
                        )}
                      </a>
                    </Box>
                  </TextContent>
                </SpaceBetween>
              </Form>
            </form>
          </Container>
        </AccountLinkContainer>
      </>
    );
  } else {
    return <Error config={config} details="Auth Token Not Found" />;
  }
};

export default MultipleExistingLwaSupport;
