import { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  Alert,
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import CardContainer from '../components/CardContainer';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';
import HelpLink from '../components/HelpLink';
import Main from '../components/Main';
import metricsService from '../services/metrics';
import { AppURL } from '../constants/urls';
import useQueryEmailProvider from '../hooks/useQueryEmailProvider';
import usePageTitle from '../hooks/usePageTitle';
import { startAuthFlow } from '../services/auth';
import { IMPRESSIONS } from '../constants/metrics';
import { AppConfig } from '../types/app';
import emailLookupMessages from '../i18n/emailLookup.messages';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import styles from './EnterpriseEmailLookup.module.css';
import { IdProvider } from '../types/auth';
import { useAmplifyConfig } from '../hooks/useAmplifyConfig';

// Same as used for AWSLabsGandalfSignInUI email OTP, https://tiny.amazon.com/phl0x69x/codeamazpackAWSLblob0659src.
const emailRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

function validateEmail(email: string) {
  return emailRegExp.test(email);
}

function ESSOPage({
  config,
  providers,
}: {
  config: AppConfig;
  providers: IdProvider[];
}) {
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(emailLookupMessages.pageTitle));
  const history = useHistory();
  const [emailValue, setEmailValue] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { isLoading, error, result, queryEmailDomain, resetResult } =
    useQueryEmailProvider(config, providers);
  const metricsPublisher = useRef(
    metricsService.getPublisher('EnterpriseEmailLookup')
  );
  const stateStorage = useStateStorage();
  const nonceGenerator = useNonceGenerator();
  useAmplifyConfig(config, result?.provider);
  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
  }, []);
  // Redirect to the provider if found.
  useEffect(() => {
    if (result?.provider) {
      metricsPublisher.current.publishCounterMonitor('LookupSuccessful', 1);
      startAuthFlow({
        provider: result.provider,
        config,
        storage: stateStorage,
        nonceGenerator,
      });
      setIsLoggingIn(true);
    }
  }, [config, result, stateStorage, nonceGenerator]);
  // Capture metric when enterprise domain was not found.
  useEffect(() => {
    if (result?.isFound === false) {
      metricsPublisher.current.publishCounterMonitor('NotFound', 1);
    }
  }, [result]);
  // Capture metric when the API request fails.
  useEffect(() => {
    if (error) {
      metricsPublisher.current.publishCounterMonitor('Error', 1);
      metricsPublisher.current.publishStringTruncate(
        'ErrorMsg',
        error.toString()
      );
      metricsPublisher.current.publishStringTruncate(
        'UserAgent',
        navigator.userAgent
      );
    }
  }, [error]);

  const handleFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const isValid = validateEmail(emailValue);
    setIsValidEmail(isValid);
    if (!isValid) return;
    queryEmailDomain(emailValue);
  };

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  const isLoadingOrLoggingIn = isLoading || isLoggingIn;

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          <CardContainer header={formatMessage(emailLookupMessages.header)}>
            <form onSubmit={handleFormSubmit} noValidate>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      formAction="none"
                      disabled={isLoadingOrLoggingIn}
                      onClick={() => {
                        history.push(
                          `${AppURL.Login}${window.location.search}`
                        );
                      }}
                      data-testid="email-back"
                    >
                      {formatMessage(emailLookupMessages.backButton)}
                    </Button>
                    <Button
                      variant="primary"
                      formAction="submit"
                      data-testid="email-submit"
                      loading={isLoadingOrLoggingIn}
                    >
                      {formatMessage(emailLookupMessages.submitButton)}
                    </Button>
                  </SpaceBetween>
                }
              >
                <SpaceBetween direction="vertical" size="s">
                  <FormField
                    label={formatMessage(emailLookupMessages.emailInputLabel)}
                    errorText={
                      isValidEmail
                        ? ''
                        : formatMessage(
                            emailLookupMessages.invalidEmailValidation
                          )
                    }
                  >
                    <Input
                      type="email"
                      value={emailValue}
                      disableBrowserAutocorrect={true}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus={true} // OK to use since this is the only purpose of the page.
                      onChange={(event) => {
                        resetResult();
                        setIsValidEmail(true);
                        setEmailValue(event.detail.value);
                      }}
                      className={styles.mobileInput}
                      data-testid="email-input"
                    />
                  </FormField>
                  <div aria-live="assertive">
                    {Boolean(result && !result.isFound) && (
                      <Alert type="warning" data-testid="email-alert">
                        {formatMessage(
                          emailLookupMessages.organizationNotFoundInfo
                        )}
                      </Alert>
                    )}
                    {error && (
                      <Alert type="warning" data-testid="email-errorAlert">
                        {formatMessage(emailLookupMessages.lookupRequestError)}
                      </Alert>
                    )}
                  </div>
                </SpaceBetween>
              </Form>
            </form>
          </CardContainer>
          <HelpLink />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default ESSOPage;
